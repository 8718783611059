.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navheader{        
  margin-top: 15px;     
  font-size: 18px;       
  color :black !important;        
  background-color: #b3beca!important        
}     
.btnspace    
{    
margin: 15px;    
}


.slider_cards-container {
  display: flex;
  border-radius: 6px;
  color: #ffffff;
  background: #eee;
  padding: 1rem;
  box-shadow: 0 0 1rem #ccc inset;
  overflow-x: auto;
  position: relative;
  height: 200px;
  
  &::-webkit-scrollbar {
    display: none;
  }
}

.slider_card {
  flex: 1 0 250px;
  box-shadow: 0 1px 1rem -4px #ccc;
  background: #fff;
  margin: 1rem;
  overflow: hidden;
  border-radius: 6px;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }
}

.slider_card-content {
  padding: 1rem;
}

@media screen and (max-width: 625px) {
  .container {
    width: 100%
  }
  
  .slider_cards-container {
    padding: .5rem;
  }
  
  .slider_card {
    margin: .3rem;
  }
}